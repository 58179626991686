<template>
  <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0"/>
  <HeaderComponent/>
  <h3 style="text-align: center; margin-bottom: 20px; font-size: 22px" v-if="this.$route.query.order">Заказ №
    {{ this.$route.query.order }} - {{ layout_user_name }}</h3>
  <div style="position: absolute; margin-left: 4vw">
    <div style="margin-bottom: 30px;">
      <a href="#" id="back_button" style="font-size: 20px; color: black" @click="this.$router.go(-1)">
        Назад
      </a>
    </div>

    <div v-for="type in current_types" v-bind:key="type">
      <div>
        <div style="display: inline-block;">
          <button style="background: transparent; border: none !important;"
                  :disabled="elements[type] === -1"
                  @click="hide_element(type)">
        <span class="material-symbols-outlined" v-if="hidden_elements_by_one[type]"
              style="margin-right: 5px;">visibility_off</span>
            <span class="material-symbols-outlined" v-else style="margin-right: 5px;">visibility</span>
          </button>
        </div>
        <div style="display: inline-block; padding-bottom: 15px; vertical-align: middle">
          {{ type }}
        </div>
      </div>
    </div>
  </div>
  <select style="width: 55vw; margin-left: 20vw" v-model="sex_selected" @change="sex_change($event)"
          class="form-select">
    <option v-for="option in sex_options" :value="option.value" :key="option.value">
      {{ option.text }}
    </option>
  </select>
  <div style="font-family: Ubuntu,serif; padding-top: 20px; margin-left: 20vw">
    <div id="viewer" @mousemove="mouseMove" @mousedown.left="mouseDownLeft" @mouseup.left="mouseUpLeft"
         @mousewheel='mouseWheel' @DOMMouseScroll="FireFoxMouseWheel" oncontextmenu="return false;"
         @mousedown.right="mouseDownRight" @mouseup.right="mouseUpRight"
         @mouseenter="hover = true" @mouseleave="hover = false">
    </div>
    <div id="description">
      <div v-for="model_type in Object.keys(models_descriptions)" v-bind:key="model_type">
        <div v-if="Object.keys(models_descriptions[model_type]).length && !hidden_elements_by_one[model_type]">
          <div style="font-weight: bold">
            {{ model_type }}
          </div>
          <div style="text-indent: 20px;" v-for="model_description in models_descriptions[model_type]"
               v-bind:key="model_description">
            {{ model_description["description"] }}
          </div>
          <br>
        </div>
      </div>
    </div>
  </div>

  <FooterComponent/>
</template>

<script>
import * as Three from 'three';
import {reactive, defineComponent} from 'vue';
import {GLTFLoader} from "three/addons/loaders/GLTFLoader";
import "vue3-colorpicker/style.css";

import 'vue3-treeselect/dist/vue3-treeselect.css'
import axios from "axios";

import HeaderComponent from "@/company/HeaderComponent";
import FooterComponent from "@/company/FooterComponent";


export default defineComponent({
  name: 'App',
  components: {FooterComponent, HeaderComponent},

  data() {
    return {
      layout_to_copy: '',
      layout_to_paste: '',
      menu_open: 0,
      isPanelOpen: false,
      layout_name: '',
      isLoading: true,
      is_model_loaded: [],
      show_save_modal: 0,
      show_clear_modal: 0,
      show_copy_modal: 0,
      show_paste_modal: 0,
      new_element: -1,
      models_raw: [],
      current_types: [],
      models_properties: {},
      models_descriptions: {},
      hover: false,
      hidden_elements_by_one: {},
      translates: [],
      work: "Factory",
      sex: "Man",
      season: "Summer",
      last_element_id: 0,
      start_color: "rgb(215, 215, 215)",
      second_color: "rgb(215, 215, 215)",
      third_color: "rgb(215, 215, 215)",
      ropes_color: "rgb(0, 0, 0)",
      elements: {},
      active_elements: {},
      elements_to_models: {},
      elements_color: {},
      models_ids: {},
      sex_selected: 'Man',
      sex_options: [
        {text: 'Мужчина', value: 'Man'},
        {text: 'Женщина', value: 'Woman'},
      ],
      season_selected: 'Summer',
      season_options: [
        {text: 'Лето', value: 'Summer'},
        {text: 'Зима', value: 'Winter'},
      ],
      work_selected: 'Factory',
      work_options: [
        {text: 'Рабочий', value: 'Factory'}
      ],
      full_tree_options: [],
      tree_options: [],
      last_added_element: '',
      layout_user_name: '',

      new_layout: 0,  // bool
    }
  },
  methods: {
    init: function () {
      this.targetElement = document.querySelector('#viewer');

      this.rotation_angle = 0

      this.color = reactive({
        alpha: 1,
      });

      this.loader = new GLTFLoader();
      this.scene = new Three.Scene();

      this.models = [];

      this.lastMPos = {
        x: 0,
        y: 0
      };
      this.mouseClickedLeft = false;
      this.mouseClickedRight = false;

      this.container = document.getElementById('viewer');

      this.camera = new Three.PerspectiveCamera(60, this.container.clientWidth / this.container.clientHeight, 0.01, 100);
      this.camera.position.y = 1.0;
      this.camera.position.z = 2.5;
      this.camera.lookAt(0, 0.7, -1)

      const color = 0xFFFFFF;
      const intensity = 1;

      const light = new Three.AmbientLight(color, 1.63);
      this.scene.add(light);


      const light1 = new Three.DirectionalLight(color, intensity);
      light1.castShadow = true;
      light1.position.set(5, 5, 5);
      light1.target.position.set(-2, 0, -1);
      light1.shadow.mapSize.width = 4096;
      light1.shadow.mapSize.height = 4096;
      this.scene.add(light1);
      this.scene.add(light1.target);

      const light2 = new Three.DirectionalLight(color, intensity);
      light2.castShadow = true;
      light2.position.set(-6, 3, 5);
      light2.target.position.set(-2, 0, -1);
      light2.shadow.mapSize.width = 4096;
      light2.shadow.mapSize.height = 4096;
      this.scene.add(light2);
      this.scene.add(light2.target);

      const loadManager = new Three.LoadingManager();
      const loader = new Three.TextureLoader(loadManager);
      const lensiz_texture = loader.load("lensiz_logo.jpg");

      let width = 8;

      const logoGeo = new Three.PlaneGeometry(width, width / 2.4);
      const logoMat = new Three.MeshPhongMaterial({map: lensiz_texture});

      const logo = new Three.Mesh(logoGeo, logoMat);
      logo.receiveShadow = true;
      logo.position.x = 0;
      logo.position.y = 1.1;
      logo.position.z = -1.99;
      this.scene.add(logo);

      const planeGeo = new Three.PlaneGeometry(40, 40);
      const planeMat = new Three.MeshPhongMaterial({color: 0xFFFFFF});
      const mesh1 = new Three.Mesh(planeGeo, planeMat);
      mesh1.receiveShadow = true;
      mesh1.rotation.x = Math.PI * -.5;
      mesh1.position.y = -1;
      this.scene.add(mesh1);

      const mesh2 = new Three.Mesh(planeGeo, planeMat);
      mesh2.receiveShadow = true;
      mesh2.position.z = -2;
      this.scene.add(mesh2);

      this.renderer = new Three.WebGLRenderer({antialias: true});
      this.renderer.shadowMap.enabled = true;
      this.renderer.setClearColor(0xaaaaaa, 1);

      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
      this.container.appendChild(this.renderer.domElement);
    },

    get_translates() {
      axios.get("/api/customer/get_translates", {
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => {
        this.translates = response.data;
        this.get_models();
      })
    },

    get_models() {
      axios.post("/api/customer/models", {
        "work": String(this.work),
        "sex": String(this.sex),
        "season": String(this.season),
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      })
          .then((response) => {
            let new_tree_select = [{
              id: -1,
              label: "Выберите элемент...",
              isDisabled: true
            }];
            this.models_raw = response.data;
            for (let model in this.models_raw) {
              model = this.models_raw[model]
              this.models_properties[model["path_to_model"] + '/' + model["model"].slice(0, -4)] = {
                "model_name": model["name"],
                "conflicts_to": model["conflicts_to"],
                "description": model["description"],
                "color_count": model["color_count"],
                "type": model["type"],
              }
              if (model["type"].slice(-5,) === "_main") {
                if (!this.current_types.includes(model["type"].slice(0, -5))) {
                  this.current_types.push(model["type"].slice(0, -5))
                }
              }
            }

            for (let type in this.current_types) {
              this.hidden_elements_by_one[this.current_types[type]] = 0;
            }
            this.tree_options = JSON.parse(JSON.stringify(new_tree_select));
            this.full_tree_options = JSON.parse(JSON.stringify(new_tree_select));
          }).then(() => {
            this.generate_description()
      })
      this.isLoading = false;
    },

    generate_description() {
      for (let type in this.current_types) {
        this.models_descriptions[this.current_types[type]] = []
      }
      for (let element in this.elements) {
        let model_info = this.models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + this.elements[element]]
        if (model_info) {
          for (let type in this.models_descriptions) {
            if (model_info['type'] === type || model_info['type'].slice(0, -5) === type) {
              this.models_descriptions[type].push({
                "name": this.elements[element],
                "description": model_info["description"],
                "priority": model_info["priority"]
              })
            }
          }
        }
      }
      for (let type in this.models_descriptions) {
        this.models_descriptions[type] = this.models_descriptions[type].sort((a, b) => {
              return ((a["priority"] < b["priority"]) ? -1 : ((a["priority"] > b["priority"]) ? 1 : 0));
            }
        )
      }
    },

    hide_element(type) {
      this.hidden_elements_by_one[type] = 1 - this.hidden_elements_by_one[type];

      for (let model in this.models_properties) {
        if (this.models_properties[model]["type"] === type || this.models_properties[model]["type"] === type + "_main") {
          let model_name = model.split("/").splice(3,).join('/')
          for (let el in this.elements) {
            if (this.elements[el] === model_name && this.hidden_elements_by_one[el] !== 1) {
              this.models[this.elements_to_models[el]].visible = this.hidden_elements_by_one[type] !== 1;
            }
          }
        }
      }
    },

    animate: function () {
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },

    changeRopesColor: function () {
      let color = this.ropes_color
      for (let i = 0; i < this.models.length; i++) {
        this.models[i].traverse(function (model) {
          if (model.isMesh) {
            if (model.material.name.indexOf("Rope") >= 0) {
              model.material.color = new Three.Color(color);
            }
          }
        })
      }
    },

    sex_change: function (model) {
      if (typeof (model) != "string") {
        model = model.target.value;
      }
      this.sex = model
      this.get_translates();

      this.loader.load(`models/${this.sex}.glb`, (gltf) => {
            gltf.scene.position.y = -1;
            gltf.scene.position.z = -0.8;
            gltf.scene.rotation.y = this.rotation_angle;
            gltf.scene.traverse(function (model) {
              model.castShadow = true;
            })
            for (let i = 0; i < this.models.length; i++) {
              this.scene.remove(this.models[i]);
            }
            if (this.models.length)
              this.is_model_loaded = new Array(this.models.length - 1).fill(0)
            this.models = [];
            this.models.push(gltf.scene);
            this.scene.add(gltf.scene);
            for (let key in this.elements) {
              if (this.elements[key] !== -1) {
                this.load_model(this.elements[key], this.elements_color[key], false, key, this.ropes_color)
              }
            }
          }
      );
    },

    load_model: function (model_name, color, sex_change_flag, index) {
      if (typeof (model_name) != "string") {
        model_name = model_name.target.value;
      }
      let rope_color = this.ropes_color;
      let second_color = this.second_color;
      let third_color = this.third_color;

      let work = this.work;
      let sex = this.sex;
      let season = this.season;

      this.loader.load(`models/${this.work}/${this.season}/${this.sex}/${model_name}.glb`, (gltf) => {
        gltf.scene.position.y = -1;
        gltf.scene.position.z = -0.8;
        gltf.scene.rotation.y = this.rotation_angle;
        gltf.scene.traverse(function (model) {
          model.castShadow = true;
          model.name = model_name
          if (model.isMesh) {
            if (color) {
              model.material.color = new Three.Color(color[0]);
              if (model.material.name.endsWith("_second")) {
                if (color[1] !== -1) {
                  model.material.color = new Three.Color(color[1]);
                } else {
                  model.material.color = second_color;
                }
              }
              if (model.material.name.endsWith("_third")) {
                if (color[2] !== -1) {
                  model.material.color = new Three.Color(color[2]);
                } else {
                  model.material.color = third_color;
                }
              }
              if (model.material.name.endsWith("_black")) {
                model.material.color = new Three.Color(0, 0, 0);
              }
            }
            if (model.material.name.indexOf("Rope") >= 0) {
              model.material.color = new Three.Color(rope_color);
            }
          }
        });
        this.models.push(gltf.scene);
        this.elements_to_models[index] = this.models.length - 1;
        this.is_model_loaded[index] = 1;
        this.scene.add(gltf.scene);

        if (this.hidden_elements_by_one[index] === 1) {
          gltf.scene.visible = false;
          for (let index in this.hidden_elements_by_one) {
            if (this.hidden_elements_by_one[index] === 1) {
              let type = this.models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + this.elements[index]]["type"]
              if (type.slice(-5,) === "_main") {
                let need_to_hide_type = type.slice(0, -5)
                for (let model in this.models_properties) {
                  if (this.models_properties[model]["type"] === need_to_hide_type) {
                    let model_name = model.split("/").splice(3,).join('/')
                    for (let el in this.scene.children) {
                      if (this.scene.children[el].name === model_name) {
                        this.scene.children[el].visible = false;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }, undefined, function (error) {
        console.log(`models/${work}/${season}/${sex}/${model_name}.glb`);
        console.error(error);
      });
    },

    mouseDownLeft() {
      this.mouseClickedLeft = true;
    },

    mouseUpLeft() {
      this.mouseClickedLeft = false;
    },

    mouseDownRight() {
      this.mouseClickedRight = true;
    },

    mouseUpRight() {
      this.mouseClickedRight = false;
    },

    mouseWheel(event) {
      this.camera.position.z = this.camera.position.z + event.deltaY / 500
      this.camera.position.z = Math.max(this.camera.position.z, -0.2)
      this.camera.position.z = Math.min(this.camera.position.z, 5)
    },

    FireFoxMouseWheel(event) {
      event.preventDefault();
      this.camera.position.z = this.camera.position.z + event.detail / 20
      this.camera.position.z = Math.max(this.camera.position.z, -0.2)
      this.camera.position.z = Math.min(this.camera.position.z, 5)
    },

    mouseMove(event) {
      if (this.mouseClickedLeft) {
        for (let i = 0; i < this.models.length; i++) {
          this.models[i].rotation.y -= (this.lastMPos.x - event.clientX) * .005;
          this.rotation_angle = this.models[i].rotation.y
        }
      }
      if (this.mouseClickedRight) {
        this.camera.position.y -= (this.lastMPos.y - event.clientY) * 0.001;
        this.camera.position.y = Math.max(this.camera.position.y, -0.6)
        this.camera.position.y = Math.min(this.camera.position.y, 2.2)

        this.camera.position.x += (this.lastMPos.x - event.clientX) * 0.002;
        this.camera.position.x = Math.max(this.camera.position.x, -0.3)
        this.camera.position.x = Math.min(this.camera.position.x, 0.3)
      }

      this.lastMPos = {
        x: event.clientX,
        y: event.clientY
      };
    },
  },

  watch: {
    elements: {
      handler(val) {
        localStorage.setItem("elements", JSON.stringify(this.elements));
        for (let key in val) {
          if (!(key in Object.keys(this.active_elements)) || val[key] !== this.active_elements[key]) {
            if (val[key] !== -1) {
              this.UpdateModel(val[key], key);
              this.last_added_element = val[key];
            }
          }
        }
        Object.assign(this.active_elements, val)
      },
      deep: true,
    },

    hover: function () {
      if (this.hover) {
        document.body.style.paddingRight = "6px";
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.paddingRight = "0px";
        document.body.style.overflow = "auto";
      }
    },
  },

  mounted() {
    this.init();
    if (this.$route.query.order) {
      axios.post("/api/company/get_order", {
            "token": String(localStorage.getItem("access_token")),
            "order_id": this.$route.query.order,
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        let elemenents_data = response.data
        this.active_elements = elemenents_data["active_elements"];
        this.elements = elemenents_data["elements"];
        this.elements_color = elemenents_data["elements_color"];
        this.elements_to_models = elemenents_data["elements_to_models"];
        this.hidden_elements_by_one = elemenents_data["hidden_elements_by_one"];
        this.last_element_id = elemenents_data["last_element_id"];
        this.work_selected = elemenents_data["work"];
        this.season_selected = elemenents_data["season"];
        this.sex_selected = elemenents_data["sex"];
        this.layout_user_name = elemenents_data["name"];

        this.ropes_color = elemenents_data["ropes_color"];
        this.sex = this.sex_selected;
        this.season = this.season_selected;
        this.work = this.work_selected;
      }).then(() => {
        this.get_translates();
        this.animate();

        this.sex_change(this.sex);
      }).catch((reason) => {
        alert(reason.response.data.detail)
      })
    }
  },
})
</script>

<style>
@font-face {
  font-family: Verdana;
  src: url('~@/assets/fonts/Verdana.ttf');
}

@font-face {
  font-family: Ubuntu;
  src: url('~@/assets/fonts/Ubuntu.ttf');
}

#viewer {
  margin-bottom: 20px;
  width: 1000px;
  height: 400px;
  text-align: center;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

body::-webkit-scrollbar {
  width: 6px; /* ширина scrollbar */
}

body::-webkit-scrollbar-track {
  background-color: #FFFFFF; /* цвет дорожки */
}

body::-webkit-scrollbar-thumb {
  background-color: #062051; /* цвет плашки */
  border-radius: 10px; /* закругления плашки */
  border: 1px solid #FFFFFF; /* padding вокруг плашки */
}

</style>
