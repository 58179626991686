<template>
  <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0"/>
  <div style="min-height: 100vh">
    <header style="font-family: Ubuntu; vertical-align: middle">
      <img src="@/assets/logo.png" id="logo"/>
      <div style="margin-right: 3vw; float: right">
        <a id="burger" href="#" class="header_link" style="color: black"
           @click.prevent="menu_open = 1 - menu_open">
          Меню
        </a>
        <a href="/customer/orders" class="header_link" style="color: black">Мои проекты</a>
        <a href="/customer/instruction_video" class="header_link" style="color: black">Видео-инструкция</a>
        <a href="/customer/instruction_text" class="header_link" style="color: black">Текстовая инструкция</a>
      </div>
      <div style="display: table; float: right; padding-right: 5vw">
        <div style="display: table-cell;">
          <a style="display: table-row">
            8 (812) 660 51 41
          </a>
          <a style="display: table-row">
            8 (911) 849 37 70
          </a>
          <a style="display: table-row">
            constract@lensiz.ru
          </a>
        </div>
        <div style="display: table-cell; padding-left: 1vw; text-align: center; vertical-align: middle">
          <button class="btn btn-danger" @click="open_call_modal(); show_call_modal = 1;" type="button"
                  data-bs-toggle="modal" data-bs-target="#callModal"
                  style="height: 2.5vw; background-color: #cb292f">
            Заказать звонок
          </button>
        </div>
      </div>
    </header>

    <hr style="width: 92vw; margin-left: 4vw; margin-top: 0vw">
    <div class="sidebar">
      <div class="sidebar-backdrop" @click="menu_open=false" v-if="menu_open"></div>
      <transition name="slide">
        <div v-if="menu_open" class="sidebar-panel">
          <ul class="sidebar-panel-nav">
            <li class="menu_button list-group-item" style="border-radius: 10px">
              <a href="/constructor" class="menu_text">Конструктор</a></li>
            <li class="menu_button list-group-item">
              <a href="/customer/orders" class="menu_text">Мои проекты</a></li>
            <li class="menu_button list-group-item">
              <a href="/customer/offer" class="menu_text">Оферта</a></li>
            <li class="menu_button list-group-item">
              <a href="/customer/instruction_text" class="menu_text">Инструкция (текстовая)</a></li>
            <li class="menu_button list-group-item">
              <a href="/customer/instruction_video" class="menu_text">Видео-инструкция</a></li>
            <li class="menu_button list-group-item">
              <a href="/customer/edit" class="menu_text">Мой профиль</a></li>
          </ul>
        </div>
      </transition>
    </div>
    <a href="#" id="back_button" style="position: absolute; margin-left: 4vw; font-size: 20px; color: black"
       @click="this.$router.go(-1)">
      Назад
    </a>
    <div>
      <div id="wrapper" style="font-family: Ubuntu,serif">
        <h3 style="text-align: center; margin-bottom: 20px; font-size: 22px" v-if="this.$route.query.order">Заказ №
          {{ this.$route.query.order }} - {{ layout_user_name }}</h3>
        <div id="middle_block">
          <div id="options_select">
            <div class="select_option">
              <select v-model="work_selected" @change="work_change($event)" class="form-select">
                <option v-for="option in work_options" :value="option.value" :key="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>

            <div class="select_option" style="padding-left: 30px">
              <select v-model="season_selected" @change="season_change($event)" class="form-select">
                <option v-for="option in season_options" :value="option.value" :key="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>

            <div class="select_option" style="padding-left: 30px">
              <select v-model="sex_selected" @change="sex_change($event)" class="form-select">
                <option v-for="option in sex_options" :value="option.value" :key="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div id="viewer" @mousemove="mouseMove" @mousedown.left="mouseDownLeft" @mouseup.left="mouseUpLeft"
               @mousewheel='mouseWheel' @DOMMouseScroll="FireFoxMouseWheel" oncontextmenu="return false;"
               @mousedown.right="mouseDownRight" @mouseup.right="mouseUpRight"
               @mouseenter="hover = true" @mouseleave="hover = false">
          </div>
          <div id="description">
            <div v-for="model_type in Object.keys(models_descriptions)" v-bind:key="model_type">
              <div v-if="Object.keys(models_descriptions[model_type]).length">
                <div style="font-weight: bold">
                  {{ model_type }}
                </div>
                <div style="text-indent: 20px;" v-for="model_description in models_descriptions[model_type]"
                     v-bind:key="model_description">
                  {{ model_description["description"] }}
                </div>
                <br>
              </div>
            </div>
          </div>
        </div>

        <div id="select" v-if="!isLoading">
          <div id="colors">
            <div style="text-align: center;">
              <div style="padding-bottom: 10px; display: inline-block">
                Базовый цвет:
                <color-picker style="width: 10%" v-model:pureColor="start_color"
                              @pureColorChange="changeStartColor($event)"
                              :disableAlpha="true" class="inline-element"/>
              </div>

              <div style="padding-bottom: 10px; display: inline-block">
                Цвет ниток:
                <color-picker style="width: 10%" v-model:pureColor="ropes_color"
                              @pureColorChange="changeRopesColor($event)"
                              :disableAlpha="true" class="inline-element"/>
              </div>
            </div>

            <div style="text-align: center; margin-left: 19px">
              <div style="padding-bottom: 10px; display: inline-block">
                Доп. цвет 1:
                <color-picker style="width: 10%" v-model:pureColor="second_color"
                              @pureColorChange="changeSecondColor($event)"
                              :disableAlpha="true" class="inline-element"/>
              </div>

              <div style="padding-bottom: 10px; display: inline-block">
                Доп. цвет 2:
                <color-picker style="width: 10%" v-model:pureColor="third_color"
                              @pureColorChange="changeThirdColor($event)"
                              :disableAlpha="true" class="inline-element"/>
              </div>
            </div>
          </div>

          <hr style="margin-left: 30px">

          <ul class="row-container" style="margin-left: 0; padding-left: 0">
            <n-collapse>
              <n-collapse-item v-for="model_type in Object.keys(this.current_types)"
                               v-bind:key="model_type" :title="model_type" :name="model_type">
                <li v-for="index in Object.keys(elements).filter((object) => (this.models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + elements[object]] && this.models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + elements[object]]['type'].includes(model_type)) || elements[object] === -1)"
                    v-bind:key="index" class="row_in_select"
                    style="list-style-type: none; padding-bottom: 10px">
                  <button style="background: transparent; border: none !important; margin-top: 5px"
                          :disabled="elements[index] === -1"
                          @click="hide_element(index)">
                      <span class="material-symbols-outlined" v-if="hidden_elements_by_one[index]"
                            style="margin-right: 5px;">visibility_off</span>
                    <span class="material-symbols-outlined" v-else style="margin-right: 5px;">visibility</span>
                  </button>

                  <div style="width: 15%; display: inline-block; margin-right: 5px">
                    <div>
                      <color-picker v-model:pureColor="elements_color[index][0]"
                                    @pureColorChange="change_main_color($event, index)"
                                    :disableAlpha="true" class="inline-element"/>
                    </div>
                    <div
                        v-if="models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + elements[index]]">
                      <color-picker style="padding-left: 30px" v-model:pureColor="elements_color[index][1]"
                                    v-if="models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + elements[index]]['color_count'] >= 2"
                                    @pureColorChange="change_second_color($event, index)"
                                    :disableAlpha="true" class="inline-element"/>
                    </div>

                    <div
                        v-if="models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + elements[index]]">
                      <color-picker style="padding-left: 30px" v-model:pureColor="elements_color[index][2]"
                                    v-if="models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + elements[index]]['color_count'] >= 3"
                                    @pureColorChange="change_third_color($event, index)"
                                    :disableAlpha="true" class="inline-element"/>
                    </div>
                  </div>

                  <div style="height: 100%; display: inline-block; width: 75%;">
                    <treeselect autocomplete="off" v-model="elements[index]" :close-on-select="false"
                                :clearable="false" @close="check_if_element_added"
                                :options="tree_options[this.current_types[model_type]]['children']"
                                placeholder="Выберите элемент..." @open="open_tree_select(elements[index])"
                                :disable-branch-nodes="true"/>
                  </div>

                  <CCloseButton style="width: 10%; margin-left: 0" @click="deleteRow(index)" class="inline-element"
                                :disabled="elements[index] === -1"/>
                </li>
              </n-collapse-item>
            </n-collapse>
          </ul>

          <hr style="margin-left: 30px">
          <button class="btn btn-primary small" @click="show_save_modal = 1" type="button"
                  data-bs-toggle="modal" data-bs-target="#saveModal"
                  style="width: 70%; margin-left: 15%; color: #f3dfdf; background-color: #0353b2;">
            Сохранить макет
          </button>
          <button class="btn btn-primary small" @click="formatLayoutToCopy" type="button"
                  data-bs-toggle="modal" data-bs-target="#copyModal"
                  style="width: 70%; margin-left: 15%; color: #f3dfdf; margin-top: 10px;  background-color: #0353b2;">
            Скопировать макет
          </button>

          <button class="btn btn-primary small" @click="show_paste_modal = 1" type="button"
                  data-bs-toggle="modal" data-bs-target="#pasteModal"
                  style="width: 70%; margin-left: 15%; color: #f3dfdf; margin-top: 10px;  background-color: #0353b2;">
            Вставить макет
          </button>

          <button v-if="!new_layout" class="btn btn-primary small" @click="send_to_check" type="button"
                  style="width: 70%; margin-left: 15%; color: #f3dfdf; margin-top: 10px;  background-color: #0353b2;">
            Отправить на оценку
          </button>

          <button class="btn btn-primary small" @click="show_clear_modal = 1" type="button"
                  data-bs-toggle="modal" data-bs-target="#clearModal"
                  style="width: 70%; margin-left: 15%; color: #f3dfdf; margin-top: 10px; background-color: #0353b2;">
            Удалить все элементы
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="saveModal" tabindex="-1" style="height: 17rem" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog" style="height: 17rem; padding: 0; margin: 0; width: 100%">
      <div class="modal-content" style="height: 17rem; padding: 0; margin: 0">
        <div class="modal-header">
          <h5 class="modal-title text-danger" id="exampleModalLabel">Настройки</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <div v-if="this.$route.query.order">
              <button class="btn btn-primary mb-1" @click.prevent="new_layout = 1 - new_layout">
                <div v-if="new_layout===1">
                  Сохранить как текущий макет
                </div>
                <div v-else>
                  Сохранить как новый макет
                </div>
              </button>
              <br>
            </div>

            <div class="mb-3" v-if="new_layout">
              <label for="exampleInputEmail1" class="form-label">Название макета</label>
              <input type="text" class="form-control" placeholder="Введите название макета" v-model="layout_name">
            </div>
            <button class="btn btn-primary" @click.prevent="saveLayout" type="button" data-bs-dismiss="modal"
                    aria-label="Close">Сохранить
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="clearModal" tabindex="-1" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog" style="height: 10rem; padding: 0; margin: 0; width: 100%">
      <div class="modal-content" style="height: 10rem; padding: 0; margin: 0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin-left: auto; margin-right: 5px;margin-top: 5px;"></button>
        <h5 class="modal-title" id="exampleModalLabel" style="text-align: center">Вы действительно хотите очистить
          макет?</h5>
        <form style="text-align: center; padding-top: 20px">
          <button class="btn btn-danger" @click="clearLayout" style="font-size: 17px">Да, очистить</button>
          <button class="btn btn-primary" style="margin-left: 20px; font-size: 17px">Нет</button>
        </form>
      </div>
    </div>
  </div>

  <div class="modal fade" id="copyModal" tabindex="-1" aria-hidden="false" style="height: 13.2rem">
    <div class="modal-dialog" style="height: 10rem; padding: 0; margin: 0;">
      <div class="modal-content">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin-left: auto; margin-right: 5px;margin-top: 5px;"></button>
        <textarea readonly v-model="layout_to_copy" class="form-control"
                  style="resize: none; height: 10rem; margin: 10px; width: 96%">
        </textarea>
      </div>
    </div>
  </div>

  <div class="modal fade" id="pasteModal" tabindex="-1" aria-hidden="false" style="height: 16.3rem">
    <div class="modal-dialog" style="height: 10rem; padding: 0; margin: 0;">
      <div class="modal-content">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin-left: auto; margin-right: 5px;margin-top: 5px;"></button>
        <textarea placeholder="Вставьте макет сюда" v-model="layout_to_paste" class="form-control"
                  style="resize: none; height: 10rem; margin: 10px; width: 96%">
        </textarea>

        <button class="btn btn-primary" @click="pasteLayout"
                style="font-size: 17px; width: 96%; margin: 0 10px 10px 10px">Вставить
        </button>
      </div>
    </div>
  </div>

  <FooterComponent/>

  <div class="modal fade" id="callModal" tabindex="-1" style="height: 19rem" aria-labelledby="CallLabel"
       aria-hidden="true">
    <div class="modal-dialog" style="height: 19rem; padding: 0; margin: 0; width: 100%">
      <div class="modal-content" style="height: 19rem; padding: 0; margin: 0">
        <div class="modal-header">
          <h5 class="modal-title text-danger" id="CallLabel">Заказать звонок</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <label for="exampleInputEmail1" class="form-label">Имя</label>
            <input type="text" class="form-control" placeholder="Введите ваше имя" v-model="user_name">
            <label for="exampleInputEmail1" class="form-label">Телефон</label>
            <input type="tel" class="form-control" placeholder="Введите ваш номер телефона" v-model="user_phone">
            <br>
            <button class="btn btn-success" style="text-align: center; width: 100%;" @click="recall()">
              Свяжитесь со мной
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {CCloseButton} from '@coreui/vue'
import * as Three from 'three';
import {reactive, defineComponent} from 'vue';
import {GLTFLoader} from "three/addons/loaders/GLTFLoader";
import {ColorPicker} from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

import FooterComponent from "@/customer/FooterComponent";

import Treeselect from 'vue3-treeselect'
import 'vue3-treeselect/dist/vue3-treeselect.css'
import axios from "axios";

export default defineComponent({
  name: 'CustomerConstructor',
  components: {ColorPicker, CCloseButton, Treeselect, FooterComponent},

  data() {
    return {
      user_name: '',
      user_phone: '',

      layout_to_copy: '',
      layout_to_paste: '',
      menu_open: 0,
      isPanelOpen: false,
      layout_name: '',
      isLoading: true,
      is_model_loaded: [],
      show_save_modal: 0,
      show_clear_modal: 0,
      show_copy_modal: 0,
      show_paste_modal: 0,
      new_element: -1,
      models_raw: [],
      current_types: {},
      models_properties: {},
      models_descriptions: {},
      hover: false,
      hidden_elements_by_one: {},
      translates: [],
      work: "Factory",
      sex: "Man",
      season: "Summer",
      last_element_id: 0,
      start_color: "rgb(215, 215, 215)",
      second_color: "rgb(215, 215, 215)",
      third_color: "rgb(215, 215, 215)",
      ropes_color: "rgb(0, 0, 0)",
      elements: {},
      active_elements: {},
      elements_to_models: {},
      elements_color: {},
      models_ids: {},
      sex_selected: 'Man',
      sex_options: [
        {text: 'Мужчина', value: 'Man'},
        {text: 'Женщина', value: 'Woman'},
      ],
      season_selected: 'Summer',
      season_options: [
        {text: 'Лето', value: 'Summer'},
        {text: 'Зима', value: 'Winter'},
      ],
      work_selected: 'Factory',
      work_options: [
        {text: 'Рабочий', value: 'Factory'}
      ],
      full_tree_options: [],
      tree_options: [],
      last_added_element: '',

      new_layout: 0,  // bool
      layout_user_name: '',  // bool
    }
  },
  methods: {
    init: function () {
      this.targetElement = document.querySelector('#viewer');

      this.rotation_angle = 0

      this.color = reactive({
        alpha: 1,
      });

      this.loader = new GLTFLoader();
      this.scene = new Three.Scene();

      this.models = [];

      this.lastMPos = {
        x: 0,
        y: 0
      };
      this.mouseClickedLeft = false;
      this.mouseClickedRight = false;

      this.container = document.getElementById('viewer');

      this.camera = new Three.PerspectiveCamera(60, this.container.clientWidth / this.container.clientHeight, 0.01, 100);
      this.camera.position.y = 1.0;
      this.camera.position.z = 2.5;
      this.camera.lookAt(0, 0.7, -1)

      const color = 0xFFFFFF;
      const intensity = 1;

      const light = new Three.AmbientLight(color, 1.63);
      this.scene.add(light);


      const light1 = new Three.DirectionalLight(color, intensity);
      light1.castShadow = true;
      light1.position.set(5, 5, 5);
      light1.target.position.set(-2, 0, -1);
      light1.shadow.mapSize.width = 4096;
      light1.shadow.mapSize.height = 4096;
      this.scene.add(light1);
      this.scene.add(light1.target);

      const light2 = new Three.DirectionalLight(color, intensity);
      light2.castShadow = true;
      light2.position.set(-6, 3, 5);
      light2.target.position.set(-2, 0, -1);
      light2.shadow.mapSize.width = 4096;
      light2.shadow.mapSize.height = 4096;
      this.scene.add(light2);
      this.scene.add(light2.target);

      const loadManager = new Three.LoadingManager();
      const loader = new Three.TextureLoader(loadManager);
      const lensiz_texture = loader.load("lensiz_logo.jpg");

      let width = 8;

      const logoGeo = new Three.PlaneGeometry(width, width / 2.4);
      const logoMat = new Three.MeshPhongMaterial({map: lensiz_texture});

      const logo = new Three.Mesh(logoGeo, logoMat);
      logo.receiveShadow = true;
      logo.position.x = 0;
      logo.position.y = 1.1;
      logo.position.z = -1.99;
      this.scene.add(logo);

      const planeGeo = new Three.PlaneGeometry(40, 40);
      const planeMat = new Three.MeshPhongMaterial({color: 0xFFFFFF});
      const mesh1 = new Three.Mesh(planeGeo, planeMat);
      mesh1.receiveShadow = true;
      mesh1.rotation.x = Math.PI * -.5;
      mesh1.position.y = -1;
      this.scene.add(mesh1);

      const mesh2 = new Three.Mesh(planeGeo, planeMat);
      mesh2.receiveShadow = true;
      mesh2.position.z = -2;
      this.scene.add(mesh2);

      this.renderer = new Three.WebGLRenderer({antialias: true});
      this.renderer.shadowMap.enabled = true;
      this.renderer.setClearColor(0xaaaaaa, 1);
      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);

      this.container.appendChild(this.renderer.domElement);
    },

    array_index_object_by_id(array, id) {
      for (let i in array) {
        if (array[i]["id"] === id) {
          return i
        }
      }
      return -1
    },

    get_translates() {
      axios.get("/api/customer/get_translates", {
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => {
        this.translates = response.data;
        this.get_models();
      })
    },

    get_models() {
      axios.post("/api/customer/models", {
        "work": String(this.work),
        "sex": String(this.sex),
        "season": String(this.season),
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      })
          .then((response) => {
            let new_tree_select = [{
              id: -1,
              label: "Выберите элемент...",
              isDisabled: true
            }];
            this.models_raw = response.data
            for (let model in this.models_raw) {
              model = this.models_raw[model]
              this.models_properties[model["path_to_model"] + '/' + model["model"].slice(0, -4)] = {
                "model_name": model["name"],
                "conflicts_to": model["conflicts_to"],
                "description": model["description"],
                "color_count": model["color_count"],
                "priority": model["priority"],
                "type": model["type"],
              }
              this.models_ids[model["_id"]] = model["path_to_model"] + '/' + model["model"].slice(0, -4);

              let path_in_tree_select = model["path_to_model"].split('/').slice(3)

              //  BLOCK OF CREATING OF EXPANDING NODES
              if (path_in_tree_select.length === 1) {
                if (this.array_index_object_by_id(new_tree_select, path_in_tree_select[0]) === -1) {
                  let label_name = path_in_tree_select[0];
                  if (path_in_tree_select[0] in this.translates) {
                    label_name = this.translates[path_in_tree_select[0]]
                  }

                  new_tree_select.push({
                        id: path_in_tree_select[0],
                        label: label_name,
                        children: [{
                          id: -1,
                          label: "Выберите элемент...",
                          isDisabled: true
                        }]
                      }
                  )
                }
                new_tree_select[this.array_index_object_by_id(new_tree_select, path_in_tree_select[0])]["children"].push({
                  id: path_in_tree_select[0] + '/' + model["model"].slice(0, -4),
                  label: model["name"]
                })
              } else if (path_in_tree_select.length === 2) {
                if (this.array_index_object_by_id(new_tree_select, path_in_tree_select[0]) === -1) {
                  let label_name = path_in_tree_select[0];
                  if (path_in_tree_select[0] in this.translates) {
                    label_name = this.translates[path_in_tree_select[0]]
                  }
                  new_tree_select.push({
                        id: path_in_tree_select[0],
                        label: label_name,
                        children: [{
                          id: -1,
                          label: "Выберите элемент...",
                          isDisabled: true
                        }]
                      }
                  )
                }
                let object_index = this.array_index_object_by_id(new_tree_select, path_in_tree_select[0])
                if (this.array_index_object_by_id(new_tree_select[object_index]["children"], path_in_tree_select[0] + '/' + path_in_tree_select[1]) === -1) {
                  let label_name = path_in_tree_select[1];
                  if (path_in_tree_select[1] in this.translates) {
                    label_name = this.translates[path_in_tree_select[1]]
                  }
                  new_tree_select[object_index]["children"].push({
                        id: path_in_tree_select[0] + '/' + path_in_tree_select[1],
                        label: label_name,
                        children: []
                      }
                  )
                }

                new_tree_select[object_index]["children"][this.array_index_object_by_id(new_tree_select[object_index]["children"], path_in_tree_select[0] + '/' + path_in_tree_select[1])]["children"].push({
                  id: path_in_tree_select[0] + '/' + path_in_tree_select[1] + '/' + model["model"].slice(0, -4),
                  label: model["name"]
                })
              }
              // END OF BLOCK
            }
            this.tree_options = JSON.parse(JSON.stringify(new_tree_select));
            this.full_tree_options = JSON.parse(JSON.stringify(new_tree_select));
            for (let i in new_tree_select) {
              if (new_tree_select[i].id !== -1) {
                this.current_types[new_tree_select[i].label] = i;
              }
            }
          }).then(() => {
        this.generate_description()
      })
      this.isLoading = false;
    },

    UpdateModel(value, option) {
      this.scene.remove(this.models[this.elements_to_models[option]]);

      let color = this.elements_color[option]
      this.load_model(value, color, false, option, this.ropes_color);
    },

    addRow(val) {
      this.elements[this.last_element_id] = val;
      this.elements_color[this.last_element_id] = [this.start_color, this.second_color, this.third_color];
      this.hidden_elements_by_one[this.last_element_id] = 0;
    },

    hide_element(index) {
      this.hidden_elements_by_one[index] = 1 - this.hidden_elements_by_one[index];
      let type = this.models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + this.elements[index]]["type"]
      if (type.slice(-5,) === "_main") {
        let need_to_hide_type = type.slice(0, -5)

        for (let model in this.models_properties) {
          if (this.models_properties[model]["type"] === need_to_hide_type) {
            let model_name = model.split("/").splice(3,).join('/')
            for (let el in this.elements) {
              if (this.elements[el] === model_name && this.hidden_elements_by_one[el] !== 1) {
                this.models[this.elements_to_models[el]].visible = this.hidden_elements_by_one[index] !== 1;
              }
            }
          }
        }
      }
      this.generate_description();
      this.models[this.elements_to_models[index]].visible = this.hidden_elements_by_one[index] !== 1;
    },

    animate: function () {
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },

    changeStartColor: function () {
      for (let i in this.elements) {
        if (this.elements[i] === -1) {
          this.elements_color[i][0] = this.start_color;
        }
      }
    },

    changeSecondColor: function () {
      for (let i in this.elements) {
        if (this.elements[i] === -1) {
          this.elements_color[i][1] = this.second_color;
        }
      }
    },

    changeThirdColor: function () {
      for (let i in this.elements) {
        if (this.elements[i] === -1) {
          this.elements_color[i][2] = this.third_color;
        }
      }
    },

    changeRopesColor: function () {
      let color = this.ropes_color
      for (let i = 0; i < this.models.length; i++) {
        this.models[i].traverse(function (model) {
          if (model.isMesh) {
            if (model.material.name.indexOf("Rope") >= 0) {
              model.material.color = new Three.Color(color);
            }
          }
        })
      }
    },

    work_change: function (event) {
      if (typeof (event) != "string") {
        event = event.target.value;
      }
      this.work = event;
    },

    season_change: function (event) {
      if (typeof (event) != "string") {
        event = event.target.value;
      }
      this.season = event;
      this.isLoading = true;
      this.tree_options = {};
      this.current_types = {};
      this.models_properties = {};
      this.get_translates();

      this.clearLayout();
      if (!(Object.values(this.elements).includes(-1))) {
        this.addRow(-1);
      }
    },

    sex_change: function (model) {
      if (typeof (model) != "string") {
        model = model.target.value;
      }
      this.sex = model
      this.get_translates();

      this.loader.load(`models/${this.sex}.glb`, (gltf) => {
            gltf.scene.position.y = -1;
            gltf.scene.position.z = -0.8;
            gltf.scene.rotation.y = this.rotation_angle;
            gltf.scene.traverse(function (model) {
              model.castShadow = true;
            })
            for (let i = 0; i < this.models.length; i++) {
              this.scene.remove(this.models[i]);
            }
            if (this.models.length)
              this.is_model_loaded = new Array(this.models.length - 1).fill(0)
            this.models = [];
            this.models.push(gltf.scene);
            this.scene.add(gltf.scene);
            for (let key in this.elements) {
              if (this.elements[key] !== -1) {
                this.load_model(this.elements[key], this.elements_color[key], false, key, this.ropes_color)
              }
            }
          }
      );
    },

    load_model: function (model_name, color, sex_change_flag, index) {
      if (typeof (model_name) != "string") {
        model_name = model_name.target.value;
      }
      let rope_color = this.ropes_color;
      let second_color = this.second_color;
      let third_color = this.third_color;

      let work = this.work;
      let sex = this.sex;
      let season = this.season;

      this.loader.load(`models/${this.work}/${this.season}/${this.sex}/${model_name}.glb`, (gltf) => {
        gltf.scene.position.y = -1;
        gltf.scene.position.z = -0.8;
        gltf.scene.rotation.y = this.rotation_angle;
        gltf.scene.traverse(function (model) {
          model.castShadow = true;
          model.name = model_name
          if (model.isMesh) {
            if (color) {
              model.material.color = new Three.Color(color[0]);
              if (model.material.name.endsWith("_second")) {
                if (color[1] !== -1) {
                  model.material.color = new Three.Color(color[1]);
                } else {
                  model.material.color = second_color;
                }
              }
              if (model.material.name.endsWith("_third")) {
                if (color[2] !== -1) {
                  model.material.color = new Three.Color(color[2]);
                } else {
                  model.material.color = third_color;
                }
              }
              if (model.material.name.endsWith("_black")) {
                model.material.color = new Three.Color(0, 0, 0);
              }
            }
            if (model.material.name.indexOf("Rope") >= 0) {
              model.material.color = new Three.Color(rope_color);
            }
          }
        });
        this.models.push(gltf.scene);
        this.elements_to_models[index] = this.models.length - 1;
        this.is_model_loaded[index] = 1;
        this.scene.add(gltf.scene);

        if (this.hidden_elements_by_one[index] === 1) {
          gltf.scene.visible = false;
          for (let index in this.hidden_elements_by_one) {
            if (this.hidden_elements_by_one[index] === 1) {
              let type = this.models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + this.elements[index]]["type"]
              if (type.slice(-5,) === "_main") {
                let need_to_hide_type = type.slice(0, -5)
                for (let model in this.models_properties) {
                  if (this.models_properties[model]["type"] === need_to_hide_type) {
                    let model_name = model.split("/").splice(3,).join('/')
                    for (let el in this.scene.children) {
                      if (this.scene.children[el].name === model_name) {
                        this.scene.children[el].visible = false;
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }, undefined, function (error) {
        console.error(error);
        console.log(`models/${work}/${season}/${sex}/${model_name}.glb`);
      });
    },

    change_main_color(value, index) {
      if (this.models[this.elements_to_models[index]]) {
        this.models[this.elements_to_models[index]].traverse(function (model) {
          if (model.isMesh) {
            if (model.material.name.indexOf("Rope") < 0 && !model.material.name.endsWith("_second") && !model.material.name.endsWith("_third")) {
              model.material.color = new Three.Color(value);
            }
          }
        })
      }
    },

    change_second_color(value, index) {
      if (this.models[this.elements_to_models[index]]) {
        this.models[this.elements_to_models[index]].traverse(function (model) {
          if (model.isMesh) {
            if (model.material.name.endsWith("_second")) {
              model.material.color = new Three.Color(value);
            }
          }
        })
      }
    },

    change_third_color(value, index) {
      if (this.models[this.elements_to_models[index]]) {
        this.models[this.elements_to_models[index]].traverse(function (model) {
          if (model.isMesh) {
            if (model.material.name.endsWith("_third")) {
              model.material.color = new Three.Color(value);
            }
          }
        })
      }
    },

    mouseDownLeft() {
      this.mouseClickedLeft = true;
    },

    mouseUpLeft() {
      this.mouseClickedLeft = false;
    },

    mouseDownRight() {
      this.mouseClickedRight = true;
    },

    mouseUpRight() {
      this.mouseClickedRight = false;
    },

    mouseWheel(event) {
      this.camera.position.z = this.camera.position.z + event.deltaY / 500
      this.camera.position.z = Math.max(this.camera.position.z, -0.2)
      this.camera.position.z = Math.min(this.camera.position.z, 5)
    },

    FireFoxMouseWheel(event) {
      event.preventDefault();
      this.camera.position.z = this.camera.position.z + event.detail / 20
      this.camera.position.z = Math.max(this.camera.position.z, -0.2)
      this.camera.position.z = Math.min(this.camera.position.z, 5)
    },

    mouseMove(event) {
      if (this.mouseClickedLeft) {
        for (let i = 0; i < this.models.length; i++) {
          this.models[i].rotation.y -= (this.lastMPos.x - event.clientX) * .005;
          this.rotation_angle = this.models[i].rotation.y
        }
      }
      if (this.mouseClickedRight) {
        this.camera.position.y -= (this.lastMPos.y - event.clientY) * 0.001;
        this.camera.position.y = Math.max(this.camera.position.y, -0.6)
        this.camera.position.y = Math.min(this.camera.position.y, 2.2)

        this.camera.position.x += (this.lastMPos.x - event.clientX) * 0.002;
        this.camera.position.x = Math.max(this.camera.position.x, -0.3)
        this.camera.position.x = Math.min(this.camera.position.x, 0.3)
      }

      this.lastMPos = {
        x: event.clientX,
        y: event.clientY
      };
    },

    delete_element_from_tree_select(element_name) {
      let path = element_name.split('/')
      let index_of_first_node = -1;
      for (let i = 0; i < this.tree_options.length; i++) {
        if (this.tree_options[i].id === path[0]) {
          index_of_first_node = i;
          break
        }
      }

      let index_of_second = -1;
      for (let i = 0; i < this.tree_options[index_of_first_node]["children"].length; i++) {
        if (this.tree_options[index_of_first_node]["children"][i].id === path[0] + '/' + path[1]) {
          index_of_second = i;
          break
        }
      }

      let index_of_third = -1;
      for (let i = 0; i < this.tree_options[index_of_first_node]["children"][index_of_second]["children"].length; i++) {
        if (this.tree_options[index_of_first_node]["children"][index_of_second]["children"][i].id === path[0] + '/' + path[1] + '/' + path[2]) {
          index_of_third = i;
          break
        }
      }

      this.tree_options[index_of_first_node]["children"][index_of_second]["children"].splice(index_of_third, 1);
      if (this.tree_options[index_of_first_node]["children"][index_of_second]["children"].length === 0) {
        this.tree_options[index_of_first_node]["children"].splice(index_of_second, 1);
      }
    },

    open_tree_select(element_name) {
      this.tree_options = JSON.parse(JSON.stringify(this.full_tree_options))

      for (let element in this.elements) {
        if (this.elements[element] !== -1 && this.elements[element] !== element_name) {
          this.delete_element_from_tree_select(this.elements[element])

          let current_model = this.models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + this.elements[element]]
          for (let element_to_delete in current_model["conflicts_to"]) {
            this.delete_element_from_tree_select(this.models_ids[current_model["conflicts_to"][element_to_delete]].split('/').slice(3).join('/'));
          }
        }
      }
    },

    check_if_element_added() {
      if (!(Object.values(this.elements).includes(-1))) {
        this.last_element_id += 1;
        this.addRow(-1);
      }

      this.tree_options = JSON.parse(JSON.stringify(this.full_tree_options))
    },

    deleteRow(index) {
      this.scene.remove(this.models[this.elements_to_models[index]]);
      delete this.elements[index];
      delete this.elements_color[index];
      delete this.hidden_elements_by_one[index];

      this.tree_options = JSON.parse(JSON.stringify(this.full_tree_options))
      for (let element in this.elements) {
        if (this.elements[element] !== -1) {
          this.delete_element_from_tree_select(this.elements[element])

          let current_model = this.models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + this.elements[element]]
          for (let element_to_delete in current_model["conflicts_to"]) {
            this.delete_element_from_tree_select(this.models_ids[current_model["conflicts_to"][element_to_delete]].split('/').slice(3).join('/'));
          }
        }
      }

      this.generate_description();
    },

    saveLayout() {
      axios.post("/api/customer/save_layout", {
            "access_token": String(localStorage.getItem("access_token")),
            "order_num": Number(this.$route.query.order),
            "elements": this.elements,
            "name": this.layout_name,
            "elements_color": this.elements_color,
            "start_color": this.start_color,
            "second_color": this.second_color,
            "third_color": this.third_color,
            "ropes_color": this.ropes_color,
            "active_elements": this.active_elements,
            "elements_to_models": this.elements_to_models,
            "hidden_elements_by_one": this.hidden_elements_by_one,
            "last_element_id": this.last_element_id,
            "work": this.work,
            "season": this.season,
            "sex": this.sex,
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        alert("Макет успешно сохранён!");
        window.location.replace("/constructor?order=" + response.data["order_id"])
        this.show_save_modal = 0;
      }).catch((reason) => {
        alert(reason.response.data.detail)
      })
    },

    clearLayout() {
      for (let i = 0; i < this.models.length; i++) {
        this.scene.remove(this.models[i]);
      }
      this.elements = {};
      this.last_element_id = 0;
      this.hidden_elements_by_one = {};
      this.active_elements = {};
      this.elements_to_models = {};
      this.elements_color = {};
      localStorage.setItem("last_loaded_order", '');
      this.sex_change(this.sex)
    },

    formatLayoutToCopy() {
      let storage = {};
      Object.assign(storage, localStorage);
      this.show_copy_modal = 1;
      delete storage["refresh_token"];
      delete storage["access_token"];
      this.layout_to_copy = JSON.stringify(storage);
    },

    pasteLayout() {
      let data = JSON.parse(this.layout_to_paste);
      Object.keys(data).forEach(function (k) {
        if (["last_element_id", "sex_selected", "season_selected", "elements_to_models", "elements", "active_elements", "elements_color", "hidden_elements_by_one", "ropes_color", "start_color", "second_color", "third_color"].includes(k)) {
          localStorage.setItem(k, data[k]);
        }
      });
      location.reload()
    },

    send_to_check() {
      if (this.new_layout) {
        this.show_save_modal = 1;
        window.location.replace("/customer/order/" + String(this.$route.query.order))
      } else {
        window.location.replace("/customer/order/" + String(this.$route.query.order))
      }
    },

    generate_description() {
      this.models_descriptions = {};
      for (let type in this.current_types) {
        this.models_descriptions[type] = []
      }
      for (let element in this.hidden_elements_by_one) {
        if (this.hidden_elements_by_one[element]) {
          continue
        }
        let model_info = this.models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + this.elements[element]]
        if (model_info) {
          for (let type in this.models_descriptions) {
            if (model_info['type'] === type || model_info['type'].slice(0, -5) === type) {
              this.models_descriptions[type].push({
                "name": this.elements[element],
                "description": model_info["description"],
                "priority": model_info["priority"]
              })
            }
          }
        }
      }

      for (let element in this.hidden_elements_by_one) {
        if (this.hidden_elements_by_one[element]) {
          let model_info = this.models_properties[this.work + '/' + this.season + '/' + this.sex + '/' + this.elements[element]]
          if (model_info["type"].slice(-5, ) === "_main") {
            delete this.models_descriptions[model_info['type'].slice(0, -5)]
          }
        }
      }

      for (let type in this.models_descriptions) {
        this.models_descriptions[type] = this.models_descriptions[type].sort((a, b) => {
              return ((a["priority"] < b["priority"]) ? -1 : ((a["priority"] > b["priority"]) ? 1 : 0));
            }
        )
      }
    },

    open_call_modal() {
      axios.post("/api/customer/profile", {
            "token": localStorage.getItem("access_token"),
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        this.user_name = response.data["contact_person"];
        this.user_phone = response.data["phone"]
      })
    },

    recall() {
      axios.post("/api/customer/recall", {
            "token": localStorage.getItem("access_token"),
            "contact_person": this.user_name,
            "phone": this.user_phone,
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      )
      alert("Пожалуйста, ожидайте, мы скоро Вам перезвоним!");
      window.location.reload();
    }
  },

  watch: {
    elements: {
      handler(val) {
        localStorage.setItem("elements", JSON.stringify(this.elements));
        for (let key in val) {
          if (!(key in Object.keys(this.active_elements)) || val[key] !== this.active_elements[key]) {
            this.generate_description();
            if (val[key] !== -1) {
              this.UpdateModel(val[key], key);
              this.last_added_element = val[key];
            }
          }
        }
        Object.assign(this.active_elements, val)
      },
      deep: true,
    },

    hover: function () {
      if (this.hover) {
        document.body.style.paddingRight = "6px";
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.paddingRight = "0px";
        document.body.style.overflow = "auto";
      }
    },

    new_element: {
      handler(val) {
        this.addRow(val)
        Object.assign(this.active_elements, val)
        this.new_element = -1;
      }
    },

    hidden_elements_by_one: {
      handler() {
        localStorage.setItem("hidden_elements_by_one", JSON.stringify(this.hidden_elements_by_one));
      },
      deep: true,
    },

    work_selected: {
      handler() {
        localStorage.setItem("work_selected", JSON.stringify(this.work_selected));
      }
    },
    season_selected: {
      handler() {
        localStorage.setItem("season_selected", JSON.stringify(this.season_selected));
      }
    },
    sex_selected: {
      handler() {
        localStorage.setItem("sex_selected", JSON.stringify(this.sex_selected));
      }
    },
    last_element_id: {
      handler() {
        localStorage.setItem("last_element_id", JSON.stringify(this.last_element_id));
      }
    },
    active_elements: {
      handler() {
        localStorage.setItem("active_elements", JSON.stringify(this.active_elements));
      },
      deep: true,
    },
    elements_to_models: {
      handler() {
        localStorage.setItem("elements_to_models", JSON.stringify(this.elements_to_models));
      },
      deep: true,
    },
    elements_color: {
      handler() {
        localStorage.setItem("elements_color", JSON.stringify(this.elements_color));
      },
      deep: true,
    },
    start_color: {
      handler() {
        localStorage.setItem("start_color", JSON.stringify(this.start_color));
      },
      deep: true,
    },
    second_color: {
      handler() {
        localStorage.setItem("second_color", JSON.stringify(this.second_color));
      },
      deep: true,
    },
    third_color: {
      handler() {
        localStorage.setItem("third_color", JSON.stringify(this.third_color));
      },
      deep: true,
    },
    ropes_color: {
      handler() {
        localStorage.setItem("ropes_color", JSON.stringify(this.ropes_color));
      },
      deep: true,
    },
  },

  mounted() {
    let jivoScript = document.createElement('script')
    jivoScript.setAttribute('src', '//code.jivo.ru/widget/VnHBsq8QLH')
    document.head.appendChild(jivoScript);

    this.init();
    this.new_layout = (!this.$route.query.order) ? 1 : 0
    if (localStorage.getItem('work_selected'))
      this.work_selected = JSON.parse(localStorage.getItem('work_selected'));
    this.work = this.work_selected
    console.log(this.work)
    if (localStorage.getItem('season_selected'))
      this.season_selected = JSON.parse(localStorage.getItem('season_selected'));
    this.season = this.season_selected
    if (localStorage.getItem('sex_selected'))
      this.sex_selected = JSON.parse(localStorage.getItem('sex_selected'));
    this.sex = this.sex_selected
    if (localStorage.getItem('last_element_id'))
      this.last_element_id = JSON.parse(localStorage.getItem('last_element_id'));
    if (localStorage.getItem('elements'))
      this.elements = JSON.parse(localStorage.getItem('elements'));
    if (localStorage.getItem('active_elements'))
      this.active_elements = JSON.parse(localStorage.getItem('active_elements'));
    if (localStorage.getItem('elements_to_models'))
      this.elements_to_models = JSON.parse(localStorage.getItem('elements_to_models'));
    if (localStorage.getItem('elements_color'))
      this.elements_color = JSON.parse(localStorage.getItem('elements_color'));
    if (localStorage.getItem('ropes_color'))
      this.ropes_color = JSON.parse(localStorage.getItem('ropes_color'));
    if (localStorage.getItem('start_color'))
      this.start_color = JSON.parse(localStorage.getItem('start_color'));
    if (localStorage.getItem('second_color'))
      this.second_color = JSON.parse(localStorage.getItem('second_color'));
    if (localStorage.getItem('third_color'))
      this.third_color = JSON.parse(localStorage.getItem('third_color'));
    if (localStorage.getItem('hidden_elements_by_one'))
      this.hidden_elements_by_one = JSON.parse(localStorage.getItem('hidden_elements_by_one'));


    if (this.$route.query.order && window.performance.getEntriesByType("navigation")[0].type !== "reload") {
      axios.post("/api/customer/get_order", {
            "token": String(localStorage.getItem("access_token")),
            "order_id": this.$route.query.order,
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        let elements_data = response.data
        if (this.$route.query.changes) {
          elements_data = response.data["changes"]
        }
        this.active_elements = elements_data["active_elements"];
        this.elements = elements_data["elements"];
        this.elements_color = elements_data["elements_color"];
        this.elements_to_models = elements_data["elements_to_models"];
        this.last_element_id = elements_data["last_element_id"];
        this.hidden_elements_by_one = elements_data["hidden_elements_by_one"];
        this.work_selected = elements_data["work"];
        this.season_selected = elements_data["season"];
        this.sex_selected = elements_data["sex"];
        this.layout_user_name = response.data["name"];

        this.sex = this.sex_selected;
        this.season = this.season_selected;
        this.work = this.work_selected;

        this.sex_change(this.sex);
      }).catch((reason) => {
        alert(reason.response.data.detail)
      })
    } else {
      if (this.$route.query.order) {
        axios.post("/api/customer/get_order", {
              "token": String(localStorage.getItem("access_token")),
              "order_id": this.$route.query.order,
            }, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
            }
        ).then((response) => {
          this.layout_user_name = response.data["name"];
          this.sex_change(this.sex);
        })
      } else {
        this.sex_change(this.sex);
      }
    }

    this.get_translates();
    this.animate();

    if (!this.hidden_elements_by_one) {
      for (let i in this.elements) {
        this.hidden_elements_by_one[i] = 0;
      }
    }

    if (!(Object.values(this.elements).includes(-1))) {
      this.addRow(-1);
    }
  },
})
</script>

<style>
@font-face {
  font-family: Verdana;
  src: url('~@/assets/fonts/Verdana.ttf');
}

@font-face {
  font-family: Ubuntu;
  src: url('~@/assets/fonts/Ubuntu.ttf');
}

header {
  font-family: Ubuntu;
  display: flex;
  font-size: 1vw;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.header_link {
  font-size: 1.2vw;
  padding-left: 2vw;
  color: black;
  vertical-align: middle;
  text-decoration: none;
}

.header_link:hover {
  text-decoration: underline;
}

#logo {
  display: inline;
  vertical-align: middle;
  margin-right: 1vw;
  width: 15vw;
  padding-left: 5vw;
}

.ps {
  height: 100vh;
}

#constructor_sign {
  text-align: center;
  font-size: 40px;
  font-family: Ubuntu, serif;
  color: #24509c;
  margin-right: 5vw;
  margin-top: 3vh;
}

#middle_block {
  display: table-cell;
  height: 60vh;
}

#viewer {
  margin-bottom: 20px;
  width: 55vw;
  height: 70vh;
  text-align: center;
}

#copy-paste {
  margin-bottom: 20px;
  text-align: center
}

#select {
  right: 0;
  top: 0;
  display: table-cell;
  vertical-align: top;
  width: 37vw;
  padding-left: 20px;
  margin-left: 5vw;
  margin-right: 0;
}

#options_select {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 100%;
  padding-bottom: 20px;
}

.select_option {
  display: inline-block;
  width: 27%;
}

#wrapper {
  font-family: Ubuntu;
  margin-left: 4vw;
  width: 92vw;
  min-height: 70vh;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.row-container {
  white-space: nowrap;
}

.row_in_select > * {
  vertical-align: middle;
}

.inline-element {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

body::-webkit-scrollbar {
  width: 6px; /* ширина scrollbar */
}

body::-webkit-scrollbar-track {
  background-color: #FFFFFF; /* цвет дорожки */
}

body::-webkit-scrollbar-thumb {
  background-color: #062051; /* цвет плашки */
  border-radius: 10px; /* закругления плашки */
  border: 1px solid #FFFFFF; /* padding вокруг плашки */
}

.modal-shadow {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.39);
}

.modal {
  height: 10rem;
  padding: 0;
  margin: 0;
  background: #fff;
  border-radius: 8px;
  min-width: 420px;
  max-width: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.copy_modal {
  height: 13.2rem;
  padding: 0;
  margin: 0;
  background: #fff;
  border-radius: 8px;
  min-width: 420px;
  max-width: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-close {
  border-radius: 50%;
  color: #fff;
  background: #2a4cc7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 7px;
  right: 7px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.modal-title {
  color: #0971c7;
}

.modal-content {
  margin-bottom: 20px
}

.slide-enter-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, .5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 997;
  cursor: pointer;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: #130f40;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  padding: 3rem 20px 2rem 20px;
  width: 22vw;
}

.main-nav {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.8rem;
}

ul.sidebar-panel-nav {
  list-style-type: none;
}

ul.sidebar-panel-nav > li > a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  display: block;
  padding-bottom: 0.5em;
}

#burger {
  display: inline-block;
  padding: 0;
}
</style>
