<template>
  <div
      style="width: 20vw; height: 100vh; background-color: #002154; white-space: nowrap; display: inline-block; float: left">
  </div>
  <div class="d-flex align-items-center justify-content-center"
       style="width: 30vw; height: 100vh; padding-left: 0; float: left">
    <span class="">
      <h1 class="font-semibold text-4xl mb-3">Вход</h1>

      <p class="text-base mb-3 leading-5">
        Ещё нет аккаунта?
        <RouterLink :to="{ name: 'Регистрация' }" class="font-semibold text-primary">Зарегистрируйтесь</RouterLink>
      </p>

      <div class="form-group" style="width: 18vw">
        <label class="form-label" for="login"><span style="color: #002154; font-size: 14px"><b>ПОЧТА</b></span></label>
        <input
            id="login"
            style="width: 100%;"
            type="email"
            placeholder="Введите почту"
            name="login"
            v-model="login"
            class="form-control"
        />
        <p style="color: red">{{ email_message }}</p>
      </div>

      <div class="form-group" style="width: 18vw">
        <label class="form-label" for="psw"><span style="color: #002154; font-size: 14px;"><b>ПАРОЛЬ</b></span></label>
        <input id="psw"
               style="width: 100%;"
               type="password"
               placeholder="Введите пароль"
               class="form-control"
               name="psw"
               v-model="password">
        <p style="color: red">{{ password_message }}</p>
      </div>

      <div class="form-group">
        <div>
          <input type="checkbox" id="remember-me" name="remember" class="form-check-input" style="margin-right: 5px"/>
          <label for="remember-me" class="form-label">Запомнить меня</label>

          <a href="/forgot_password" class="font-semibold text-primary" style="float: right">
                        Забыли пароль?
          </a>
        </div>
      </div>

      <button class="btn btn-primary mt-2" id="submit_button" @click="onSubmit">Войти</button>
    </span>
  </div>

  <div class="d-flex align-items-center justify-content-center" style="margin-left: 50vw; width: 45vw; height: 100vh;">
    <iframe :src="link_to_hello_video" title="YouTube video player" style="width: 40vw; height: 45vh"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginPage",
  data() {
    return {
      login: '',
      password: '',

      email_message: '',
      password_message: '',

      link_to_hello_video: '',
    }
  },
  methods: {
    onSubmit() {
      if (!this.login) {
        this.email_message = "Введите почту"
        return
      } else this.email_message = ""
      if (!this.password) {
        this.password_message = "Введите пароль"
        return
      } else this.password_message = ""

      axios.post("/api/login", {
            "email": String(this.login),
            "password": String(this.password),
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        if (response.data["access_token"] && response.data["refresh_token"]) {
          localStorage.setItem("access_token", response.data["access_token"])
          localStorage.setItem("refresh_token", response.data["refresh_token"])
          window.location.href = '/' + response.data["role"] + "/profile"
        }
      }).catch((reason) => alert(reason.response.data.detail))
    },


    loadSettings() {
      axios.get("/api/admin/settings", {
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => {
        this.link_to_hello_video = response.data["link_to_hello_video"];
      })
    }
  },

  mounted() {
    this.loadSettings();

    let jivoScript = document.createElement('script')
    jivoScript.setAttribute('src', '//code.jivo.ru/widget/VnHBsq8QLH')
    document.head.appendChild(jivoScript)
}
}
</script>

<style>
body {
  font-family: Ubuntu;
}

#submit_button {
  width: 100%;
  background-color: #004376;
}

#submit_button:hover {
  background-color: #002154;
}
</style>
