<template>
  <div
      style="width: 20vw; height: 100vh; background-color: #002154; white-space: nowrap; display: inline-block; float: left">
  </div>
  <div class="d-flex align-items-center justify-content-center"
       style="width: 30vw; height: 100vh; padding-left: 0; float: left">
    <span class="">
      <h1 class="font-semibold text-4xl mb-3">Регистрация</h1>

      <p class="text-base mb-3 leading-5">
        Уже есть аккаунт?
        <RouterLink :to="{ name: 'Авторизация' }" class="font-semibold text-primary">Авторизуйтесь</RouterLink>
      </p>

      <div class="form-group" style="width: 18vw">
        <label class="form-label" for="login"><span style="color: #002154; font-size: 14px"><b>Почта</b></span></label>
        <input
            id="login"
            style="width: 100%;"
            type="email"
            placeholder="Введите почту"
            name="login"
            v-model="login"
            class="form-control"
        />
      </div>

      <div class="form-group" style="width: 18vw">
        <label class="form-label" for="psw"><span style="color: #002154; font-size: 14px;"><b>Пароль</b></span></label>
        <input id="psw"
               style="width: 100%;"
               type="password"
               placeholder="Введите пароль"
               class="form-control"
               name="psw"
               v-model="password">
      </div>

      <div class="form-group" style="width: 18vw">
        <label class="form-label" for="TIN"><span style="color: #002154; font-size: 14px"><b>ИНН</b></span></label>
        <input id="TIN"
               style="width: 100%;"
               placeholder="Введите ИНН"
               class="form-control"
               name="TIN"
               v-model="TIN">
      </div>

      <div class="form-group" style="width: 18vw">
        <label class="form-label" for="companyName"><span style="color: #002154; font-size: 14px;"><b>Наименование компании</b></span></label>
        <input id="companyName"
               style="width: 100%;"
               placeholder="Введите наименование компании"
               class="form-control"
               name="companyName"
               v-model="companyName">
      </div>

      <div class="form-group" style="width: 18vw">
        <label class="form-label" for="companyName"><span style="color: #002154; font-size: 14px;"><b>Контактное лицо (ФИО)</b></span></label>
        <input id="contact_person"
               style="width: 100%;"
               placeholder="Введите ваше ФИО"
               class="form-control"
               name="contact_person"
               v-model="contact_person">
      </div>

            <div class="form-group" style="width: 18vw">
        <label class="form-label" for="companyName"><span style="color: #002154; font-size: 14px;"><b>Должность</b></span></label>
        <input id="position"
               style="width: 100%;"
               placeholder="Введите вашу должность"
               class="form-control"
               name="position"
               v-model="position">
      </div>

      <div class="form-group" style="width: 18vw">
        <label class="form-label" for="phone"><span
            style="color: #002154; font-size: 14px;"><b>Телефон для связи</b></span></label>
        <input id="phone"
               style="width: 100%;"
               placeholder="Введите телефон"
               class="form-control"
               name="phone"
               v-model="phone">
      </div>

      <div class="form-group d-flex align-items-center justify-content-center">
        <div style="display: inline-block;">
          <input type="radio" class="form-check-input" value="customer" v-model="pickedRadio" checked>
          <label class="ms-2 form-check-label">
            Я покупатель
          </label>
        </div>

        <div class="m-3 ms-5" style="display: inline-block;">
          <input type="radio"  class="form-check-input" value="company" v-model="pickedRadio">
          <label class="ms-2 form-check-label">
            Я поставщик
          </label>
        </div>
      </div>

      <button class="btn btn-primary" id="submit_button" @click.prevent="onSubmit">Зарегистрироваться</button>
    </span>
  </div>

  <div class="d-flex align-items-center justify-content-center" style="margin-left: 50vw; width: 45vw; height: 100vh;">
    <iframe :src="link_to_hello_video" title="YouTube video player" style="width: 40vw; height: 45vh"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RegistrationPage",

  data() {
    return {
      login: '',
      password: '',
      TIN: '',
      companyName: '',
      contact_person: '',
      phone: '',
      position: '',
      pickedRadio: "customer",

      link_to_hello_video: '',
    }
  },

  methods: {
    onSubmit() {
      let register_url = "/api/" + this.pickedRadio + "/register"

      axios.post(register_url, {
            "email": String(this.login),
            "password": String(this.password),
            "TIN": String(this.TIN),
            "name": String(this.companyName),
            "contact_person": String(this.contact_person),
            "position": String(this.position),
            "phone": String(this.phone),
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        if (response.status === 201) {
          window.location.href = "/wait_page"
        }
      }).catch((reason) => alert(reason.response.data.detail))
    },

    loadSettings() {
      axios.get("/api/admin/settings", {
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => {
        this.link_to_hello_video = response.data["link_to_hello_video"];
      })
    }
  },

  mounted() {
    this.loadSettings();

    let jivoScript = document.createElement('script')
    jivoScript.setAttribute('src', '//code.jivo.ru/widget/VnHBsq8QLH')
    document.head.appendChild(jivoScript)
  }
}
</script>

<style scoped>
body {
  font-family: Ubuntu;
}

#submit_button {
  width: 100%;
  background-color: #004376;
}

#submit_button:hover {
  background-color: #002154;
}
</style>
